


































































































.layout-elements {
  height: 100%;
  .layout-element--draggable {
    cursor: grab;
  }
  .layout-element--summary {
    user-select: none;
  }
}
.layout-elements--summary {
  .sortable-ghost.sortable-chosen:not(.layout-element--summary) {
    color: white !important;
    background-color: red !important;
    * {
      opacity: 0.4;
    }
  }
}
